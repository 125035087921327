import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './navbar.module.css';
import logo from './ports_logo.png';
import { getBalance, getUserById } from '../user';
import { Menu } from 'lucide-react';

function Navbar({ current_page }) {
    const user_id = localStorage.getItem('user_id');
    const navigate = useNavigate();
    const [balance, setBalance] = useState(localStorage.getItem('balance'));
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [currentTime, setCurrentTime] = useState(Date.now());
    const [username, setUsername] = useState(localStorage.getItem('username'));
    useEffect(() => {
        const fetchData = async () => {
            const user_data = await getUserById(user_id);
            return user_data;
        }
        const interval = setInterval(() => {
            setCurrentTime(Date.now()); // Update state to trigger re-render
        }, 60000); // 60000 ms = 1 minute
        fetchData().then(user_data => {

            localStorage.setItem('username', user_data.username);
            localStorage.setItem('email', user_data.email);
            setUsername(user_data.username);
        });
        getBalance(localStorage.getItem('user_id')).then(data => {
            const balance = parseFloat(data); // Ensure data is a number
            if (!isNaN(balance)) {
                console.log(balance);
                localStorage.setItem('balance', balance.toFixed(1));
                setBalance(balance.toFixed(1));
            } else {
                console.error('Balance data is not a number:', data);
            }
        });
        return () => clearInterval(interval);
    }, []);

    const LogOut = () => {
        console.log('Logging out');
        localStorage.clear();
        navigate('/');
    }

    const PortMarket = () => {
        localStorage.setItem('Current Page', 'Port Market');
        navigate('/market');
    }

    const settin = () => {
        navigate('/settings');
    }

    const MyPorts = () => {
        if (user_id === null) {
            alert('Please log in to view your ports');
            navigate('/login');
        }
        localStorage.setItem('Current Page', 'My Ports');
        navigate('/home');
    }

    const BuildPort = () => {
        if (user_id === null) {
            alert('Please log in to create a port');
            navigate('/login');
        }
        navigate('/addport');
    }

    const handleLogoClick = () => {
        if (localStorage.getItem('Current Page') === 'Secret Home') {
            localStorage.setItem('Current Page', 'My Ports');
            navigate('/home');
        } else {
            const username = localStorage.getItem('username');
            if (username === 'k_admin' || username === 'reilljoh' || username === 'admin1') {
                localStorage.setItem('Current Page', 'Secret Home');
                navigate('/secret_home');
            }
        }
    }

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    return (
        <nav className={styles.navbar}>
            <img className={styles.logo} src={logo} alt='Logo' onClick={handleLogoClick} />
            <div className={styles.user_display}>{username}</div>
            <div className={styles.display}>{current_page}</div>
            <div><div className={styles.display}>${balance}</div><div className={styles.balance_label}>Total Balance</div></div>
            {/* <button className={styles.menuButton} onClick={toggleMenu}>
                <Menu size={24} />
            </button> */}
            {/* <div className={`${styles.navItems} ${isMenuOpen ? styles.showMenu : ''}`}></div> */}
            {/* <div className={styles.navItems}> */}
                <div className={styles.tab} onClick={PortMarket}>Port Market</div>
                <div className={styles.tab} onClick={MyPorts}>My Ports</div>
                <div className={styles.tab} onClick={BuildPort}>Create Ports</div>
                <div className={styles.tab} onClick={settin}>Settings</div>
                <div className={styles.sign_tab}>
                    <div className={styles.sign_button} onClick={LogOut}>Sign Out</div>
                </div>
            {/* </div> */}
        </nav>
    );
}

export default Navbar;