import React, { useState } from "react";
import { loginUser, createUser, getLoginUsers } from '../user';
import './new_login.css';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';
import {FaUser, FaLock} from 'react-icons/fa';
import { MdEmail } from "react-icons/md";
import SignUp from "./sign-up/SignUp";
import Header from '../New_Landing_Page/header';
const bcrypt = require('bcryptjs');



const Register = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const navigate = useNavigate();
    // const handleUsernameChange = (e) => {
    //   setUsername(e.target.value);
    // };
  
    // const handlePasswordChange = (e) => {
    //   setPassword(e.target.value);
    // };
    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
        console.log('reCAPTCHA token:', token);
      };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('username:', username);
        await createUser(username,password,email);
        const success = await loginUser(username, password,recaptchaToken);
          console.log('success:', success);
        if (success) {
          console.log('username1:', username);
          localStorage.setItem('username', username);
          const token = localStorage.getItem('userToken');
          const username1 = localStorage.getItem('username');
          const data = await getLoginUsers(username1)
          console.log('data:', data);
          localStorage.setItem('user_id', data.id);
          localStorage.setItem('email', data.email);
          console.log('data:', data);
          const id = data.id;
          console.log('id: ',id);
          console.log('token:', token);
          console.log('username:', username1);
      // getUsers(username)
      // .then(f => {
      //   localStorage.setItem('email', f['email']);
      //   console.log(f['email']);
      //   return f['email']
      // })
      // .catch(error => {
      //   console.error('Error fetching users:', error);
      // });
          if (!!token) {
            console.log('here:');
            localStorage.setItem('Current Page','My Ports');
            navigate(`/settings`,{ state: { message: 'Scroll to video' } });
            console.log('Login successful');
          } else {
            alert("Incorrect username or password")
            console.log('Login failed');
          }
        } else {
          alert("Incorrect username or password")
          console.log('Login failed');
        }
        // setUsername('');
        // setPassword('');
    //     onClose();
    };
    return (
        <>
        {/* // <SignUp></SignUp> */}
        <body className='login_body'>
            <div className='login_wrapper'>
                <form action="" onSubmit={handleSubmit}>
                    <h1>Register</h1>
                    <div className='input_box'>
                        <input type="username" placeholder='Username'value={username}
                            onChange={(e) => setUsername(e.target.value)} required/>
                        <FaUser className='icon'/>
                    </div>
                    <div className='input_box'>
                        <input type="email" placeholder='Email'value={email}
                            onChange={(e) => setEmail(e.target.value)} required/>
                        <MdEmail className='icon'/>
                    </div>
                    <div className='input_box'>
                        <input type="password" placeholder='Password'value={password}
                            onChange={(e) => setPassword(e.target.value)} required/>
                        <FaLock className='icon'/>
                    </div>
                    <ReCAPTCHA 
            sitekey="6LdK5jIqAAAAACwkiWC82Beb2UXCSGtLavaDvbMX"
            onChange={handleRecaptchaChange}
          />
                    <button type='Submit'>Register</button>
                    <div className='register_link'>
                        <p>Already have an account? <a href="#"><Link to="/login">Login</Link></a></p>
                    </div>
                    <div className="privacy_link">
            <p>
              Read our <Link to="/privacy">Privacy Policy</Link>
            </p>
          </div>
                    </form>
            </div>
            </body>
            </>
            
    );
    
};



export default Register;