import React, { useState, useEffect } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Button } from '@mui/material';
import styles from './page.module.css';
import {createPort, getChartData, createTemporaryPort,investPort,getDetail,getStocks,DeletePort} from '../user';
import SearchResults from './searchresults';
import Header from '../New_Header_Navigation/navbar';
import PortDraft from './port_draft';
import { Line } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';


function LineGraph({ dataa, labels, index }) {
    const data = {
      labels: labels,
      datasets: [
        {
          label: 'Sample Data',
          data: dataa,
          fill: false,
          backgroundColor: '#1B263B',
          borderColor: '#1B263B', // Default border color
          pointRadius: 0,
          pointBackgroundColor: '#1B263B',
          cubicInterpolationMode: 'monotone',
        },
      ],
    };
  
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
          labels: {
            color: '#1B263B',
          },
        },
    },
      scales: {
        x: {
          type: 'time',
          time: {
            unit: 'day', // Adjust the unit as needed (e.g., 'minute', 'hour', 'day', 'month')
            displayFormats: {
                day: ' ', // Format for the x-axis labels
              }, // Adjust the display format as needed (e.g., 'MMM D, YYYY')
               // Adjust the unit step size as needed
          },
          grid: {
            color: '#949494',
            borderColor: 'red',
            drawBorder: false, // Remove the border line on the right
            // borderDash: [0, 0], // Customize the grid line style
            drawOnChartArea: false, // Remove the grid lines within the chart area
            drawTicks: false, // Remove the ticks on the grid lines
            lineWidth: 2,
          },
        },
        y: {
            grid: {
                color: '#949494',
                borderColor: 'red',
                drawBorder: false, // Remove the border line on the top
                // borderDash: [0, 0], // Customize the grid line style
                drawOnChartArea: false, // Remove the grid lines within the chart area
                drawTicks: false, // Remove the ticks on the grid lines
                lineWidth: 2,

            },
            ticks:{
            display: false,
            }
        },
      }
}
    return (
      <div className={styles.graphContainer}>
        <Line data={data} options={options} className="canvas" />
      </div>
    );
  };
function SearchBar({ searchQuery, setSearchQuery }) {
    return (
        <div className={styles.searchbar_wrap}>
            <input
                type="text"
                placeholder="Enter Ticker or Company Name"
                value={searchQuery}
                className={styles.searchbar}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
        </div>
    );
}

function removeNulls(data) {
    if (Array.isArray(data)) {
        return data
            .map(item => removeNulls(item))
            .filter(item => item !== null);
    } else if (data !== null && typeof data === 'object') {
        return Object.fromEntries(
            Object.entries(data)
                .map(([key, value]) => [key, removeNulls(value)])
                .filter(([key, value]) => value !== null)
        );
    } else {
        return data;
    }
}

async function fetchData() {
    const raw_data = await getStocks();
    const data_raw = removeNulls(raw_data);
    const data = data_raw.map(stock => ({
        symbol: stock.symbol,
        percent: stock.changePercent,
        price: stock.price,
        volume: stock.volume,
        fullName: stock.fullName
    }));
    return data;
}
const portfolioStats = {
    AUTHOR: 'John Doe',
    STATUS: 'Active',
    PRICE: '$10,000',
    TOTAL: '$50,000',
    TODAY: '+2.5%',
    RISK: 'Medium',
    'MAX DRAWDOWN': '-15%',
    ER: '0.5%'
  };

  const defaultStocks = [
    { symbol: 'AAPL', price: 150.00, percent: '10.00', volume: 1000000, fullName: 'Apple Inc.' },
    { symbol: 'GOOGL', price: 2800.00, percent: '5.00', volume: 500000, fullName: 'Alphabet Inc.' }
];

  function MakeAPort() {
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedStocks, setSelectedStocks] = useState([]);
    const [detail,setDetail] = useState([]);
    // const [portId,setPortId] = useState(localStorage.getItem("portId"));
    const [portId,setPortId] = useState(122);
    const orPort = 122;
    const [tempPort,setTempPort] = useState(false);
    const navigate = useNavigate();
    const [refreshKey, setRefreshKey] = useState(0); // State variable to force re-render
    const [price_hist,setprice_hist] = useState([]);
    const [dateObjectHist,setdateObjectHist] = useState([]);
    async function handleBuildPort(e) {
        const stocks = selectedStocks.map(sstock => sstock.stock);
        const stockfmtstring = stocks.join(' ');
        const percentages = selectedStocks.map(sstock => sstock.percent);
        const percentagefmtstring = percentages.join(' ');
        const positions = selectedStocks.map(sstock => sstock.position);
        const position_typesfmtstring = positions.join(' ');
        const username = localStorage.getItem('username');
        const user_id = localStorage.getItem('user_id');
        const display_title = localStorage.getItem('name');
        const display_description = localStorage.getItem('description');
        const deletPort  = await DeletePort();
        console.log(deletPort);
        console.log(orPort);
        const createdPort = await createPort(display_title, username, stockfmtstring, percentagefmtstring, display_description, position_typesfmtstring,localStorage.getItem('raw_data.price'),String(orPort));
        setPortId(createdPort);
        setSelectedStocks([]);
        console.log("Created portfolio:", createdPort);
        // await investPort(createdPort, user_id, 1000);
        // navigate("/home")
    }



    async function handleBuildTempPort(e) {
        const stocks = selectedStocks.map(sstock => sstock.stock);
        const stockfmtstring = stocks.join(' ');
        const percentages = selectedStocks.map(sstock => sstock.percent);
        const percentagefmtstring = percentages.join(' ');
        const positions = selectedStocks.map(sstock => sstock.position);
        const position_typesfmtstring = positions.join(' ');
        const username = localStorage.getItem('username');
        const user_id = localStorage.getItem('user_id');
        const display_title = localStorage.getItem('name');
        const display_description = localStorage.getItem('description');

        const createdPort = await createTemporaryPort(display_title, username, stockfmtstring, percentagefmtstring, display_description, position_typesfmtstring);
        setTempPort(true);
        setPortId(createdPort);
        setSelectedStocks([]);
        console.log("Created portfolio:", createdPort);
        const f = await investPort(createdPort, user_id, 1000,"true");
        console.log(f);
        // Force the useEffect to re-run by updating the refreshKey
        setRefreshKey(prevKey => prevKey + 1);
    }

    useEffect(() => {
        async function loadData() {
            const fetchedData = await fetchData();
            setData(fetchedData);
            const user_id = localStorage.getItem("user_id");
            const port_id = 122;
            let raw_data = {};
            
            if (!tempPort) {

                raw_data = await getDetail('personal', portId, user_id);
            } else {
                try {
                    raw_data = await getDetail('temporary', portId, user_id);
                } catch {
                    alert("Sorry You can only test ports during market hours!");
                }
                
            }
            
            console.log(raw_data);
            // setSearchQuery("AAPL");
            const initialSelectedStocks = defaultStocks.map(stock => ({ stock: stock.symbol }));
            setSelectedStocks(initialSelectedStocks);
            setSearchQuery('');
  
            localStorage.setItem('name', raw_data.name);
            localStorage.setItem('description', raw_data.description);
            localStorage.setItem('raw_data.price',raw_data.price);
            const defaultPortfolioStats = {
                AUTHOR: raw_data.author,
                STATUS: raw_data.status,
                PRICE: raw_data.price,
                TOTAL: raw_data.total_pnl,
                TODAY: raw_data.pnl,
                RISK: raw_data.risk,
                'MAX DRAWDOWN': raw_data.max_drawdown,
                ER: raw_data.expense_ratio
            };
            const raw_data_card = await getChartData('invest',portId,user_id, "MAX");
            console.log(raw_data_card);
            const date_hist = raw_data_card.date_hist;
            setprice_hist(raw_data_card.value_hist);
            setdateObjectHist(date_hist.map((timestamp) => new Date(timestamp * 1000)));
            setDetail(defaultPortfolioStats);
        }

        loadData();
    }, [refreshKey]); 

    return (
        <div className={styles.structure}>
            <Header current_page={"Build-a-Port"} />

            <div className={styles.mainContent}>
                <div className={styles.searchArea}>
                    <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
                    <div className={styles.searchResults}>
                        <SearchResults searchQuery={searchQuery} setSearchQuery={setSearchQuery} data={data} selectedStocks={selectedStocks} setSelectedStocks={setSelectedStocks} />
                    </div>
                    <PortDraft data={data} selectedStocks={selectedStocks} setSelectedStocks={setSelectedStocks} />
                </div>

                <div className={styles.sidebar}>
                    {/* Portfolio Statistics */}
                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', mb: 2, color: '#1B263B' }}>
                        Portfolio Statistics
                    </Typography>
                    <TableContainer component={Paper} sx={{ mb: 4 }}>
                        <Table>
                            <TableBody>
                                {Object.entries(detail).map(([key, value]) => (
                                    <TableRow key={key}>
                                        <TableCell component="th" scope="row" sx={{ fontWeight: 'bold', color: '#1B263B' }}>
                                            {key.replace('_', ' ')}
                                        </TableCell>
                                        <TableCell align="right" sx={{ color: '#1B263B' }}>{value}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>

            {/* Graph Placeholder */}
            <Box
                sx={{
                    width: '100%',
                    height: 300,
                    bgcolor: 'grey.200',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 4,
                    borderRadius: 1
                }}
            >
                    <LineGraph dataa={price_hist} labels={dateObjectHist} index={0} />
                {/* <Typography variant="h6" color="text.secondary">
                    Portfolio Performance Graph
                </Typography> */}
            </Box>

            <Button  onClick={handleBuildTempPort}>Test Port</Button>
            <Button onClick={handleBuildPort}>Create Port</Button>
        </div>
    );
}


export default MakeAPort;
