/* global google */
import React, { useContext, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaUser, FaLock } from 'react-icons/fa';
import { loginUser, getLoginUsers, googleLogin } from '../user';
import './new_login.css';
import { AuthContext } from '../Authentication';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import SignIn from './sign-in/SignIn';
import Header from '../New_Landing_Page/header';
import Button from '@mui/material/Button';
import { GoogleIcon } from './sign-in/CustomIcons';
function escapeSingleQuotes(str) {
  return str.replace(/'/g, "''");
}

const Login = () => {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  
  // Get error message from location state
  const location = useLocation();
  const errorMessage = location.state?.error || '';

  const handleRecaptchaChange = (token) => {
    localStorage.setItem('recaptchaToken', token);
    setRecaptchaToken(token);
    console.log('reCAPTCHA token:', token);
  };

    async function handleCredentialResponse(response) {

      console.log('in handleLoginGoogle recaptchaToken:', localStorage.getItem('recaptchaToken'));
      console.log('in handleLoginGoogle response:', response.credential);

      const success = await googleLogin(response.credential,localStorage.getItem('recaptchaToken'));
      console.log('success:', success);
      if (success) {
        const token = localStorage.getItem('userToken');
        const username = localStorage.getItem('username');
        const data = await getLoginUsers(username)
        console.log('data:', data);
        localStorage.setItem('user_id', data.id);
        localStorage.setItem('email', data.email);
        console.log('data:', data);
        const id = data.id;
        console.log('id: ',id);
        console.log('token:', token);
        console.log('username:', username);
        if (!!token) {
          console.log('here:');
          localStorage.setItem('Current Page','My Ports');
          navigate(`/home`);
          console.log('Login successful');
        } else {
          alert("Incorrect username or password")
          console.log('Login failed');
        }
      } else {
        alert("Incorrect username or password/Captacha Not Verified")
        console.log('Login failed');
      }
    }

   
    useEffect(() => {
    google.accounts.id.initialize({
      client_id: "244477881106-iplsdl5s9335sn0bpjr4rq4e6uafnk40.apps.googleusercontent.com",
      callback: handleCredentialResponse
    });
    google.accounts.id.renderButton(
      document.getElementById("buttonDiv"),
      { theme: "outline", size: "large" }  // customization attributes
    );
    google.accounts.id.prompt(); // also display the One Tap dialog
  }, []);



    const handleSubmit = async (e) => {
      e.preventDefault();
      
      // Assuming loginUser sets the token in localStorage
      const success = await loginUser(usernameOrEmail, password,recaptchaToken);
        console.log('success:', success);
      if (success) {
        localStorage.setItem('username', usernameOrEmail);
        const token = localStorage.getItem('userToken');
        const username = localStorage.getItem('username');
        const data = await getLoginUsers(username)
        console.log('data:', data);
        localStorage.setItem('user_id', data.id);
        localStorage.setItem('email', data.email);
        console.log('data:', data);
        const id = data.id;
        console.log('id: ',id);
        console.log('token:', token);
        console.log('username:', username);
    // getUsers(username)
    // .then(f => {
    //   localStorage.setItem('email', f['email']);
    //   console.log(f['email']);
    //   return f['email']
    // })
    // .catch(error => {
    //   console.error('Error fetching users:', error);
    // });
        if (!!token) {
          console.log('here:');
          localStorage.setItem('Current Page','My Ports');
          navigate(`/home`);
          console.log('Login successful');
        } else {
          alert("Incorrect username or password")
          console.log('Login failed');
        }
      } else {
        alert("Incorrect username or password")
        console.log('Login failed');
      }
    };
   return (
    <>
      {/* // <SignIn></SignIn> */}
    <div className="login_body">
      <div className="login_wrapper">
        <form onSubmit={handleSubmit}>
          <h1>Login</h1>

          {/* Display error message if it exists */}
          {errorMessage && <p className="error-message" style={{ color: 'red' }}>{errorMessage}</p>}

          <div className="input_box">
            <input
              type="text"
              placeholder="Username"
              value={usernameOrEmail}
              onChange={(e) => setUsernameOrEmail(e.target.value)}
              required
            />
            <FaUser className="icon" />
          </div>
          <div className="input_box">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <FaLock className="icon" />
          </div>
          <div className="remember_forget">
            <label><input type="checkbox" />Remember Me</label>
            <a href="#">Forget Password?</a>
          </div> 
          <div className='recaptcha'>
          <ReCAPTCHA 
            sitekey="6LdK5jIqAAAAACwkiWC82Beb2UXCSGtLavaDvbMX"
            onChange={handleRecaptchaChange}
          />
          </div>

          <button type="submit">Login</button>
          <div className="register_link">
            <p>
              Don't have an account? <Link to="/register">Register</Link>
            </p>
          </div>
         
    <div id="buttonDiv" className='google_button'></div>

            <div className="privacy_link">
            <p>
              Read our <Link to="/privacy">Privacy Policy</Link>
            </p>
          </div>
        </form>
      </div>
    </div>
    </>
  );
};
export default Login;
