import React , {useState , useEffect} from 'react';
import styles from './card.module.css';
import { useNavigate } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import { parseISO, parse } from 'date-fns';
import DetailPage from '../New_Detail/detail_page';
import { AuthProvider ,AuthContext } from '../Authentication';
import { BrowserRouter, Route, Routes,redirect,Navigate } from 'react-router-dom'
import PrivateRoute from '../App';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';


function LineGraph({ dataa, labels, index }) {
    const data = {
      labels: labels,
      datasets: [
        {
          label: 'Sample Data',
          data: dataa,
          fill: false,
          backgroundColor: '#1B263B',
          borderColor: '#1B263B', // Default border color
          pointRadius: 0,
          pointBackgroundColor: '#1B263B',
          cubicInterpolationMode: 'monotone',
        },
      ],
    };
  
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
          labels: {
            color: '#1B263B',
          },
        },
    },
      scales: {
        x: {
          type: 'time',
          time: {
            unit: 'day', // Adjust the unit as needed (e.g., 'minute', 'hour', 'day', 'month')
            displayFormats: {
                day: ' ', // Format for the x-axis labels
              }, // Adjust the display format as needed (e.g., 'MMM D, YYYY')
               // Adjust the unit step size as needed
          },
          grid: {
            color: '#949494',
            borderColor: 'red',
            drawBorder: false, // Remove the border line on the right
            // borderDash: [0, 0], // Customize the grid line style
            drawOnChartArea: false, // Remove the grid lines within the chart area
            drawTicks: false, // Remove the ticks on the grid lines
            lineWidth: 2,
          },
        },
        y: {
            grid: {
                color: '#949494',
                borderColor: 'red',
                drawBorder: false, // Remove the border line on the top
                // borderDash: [0, 0], // Customize the grid line style
                drawOnChartArea: false, // Remove the grid lines within the chart area
                drawTicks: false, // Remove the ticks on the grid lines
                lineWidth: 2,

            },
            ticks:{
            display: false,
            }
        },
      }
}
    return (
      <div className={styles.graphContainer}>
        <Line data={data} options={options} className="canvas" />
      </div>
    );
  };
  
  const StyledCard = styled(Card)(({ theme }) => ({
    borderRadius: '10px',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '0 10px 20px rgba(0, 0, 0, 0.4), 0 6px 6px rgba(0, 0, 0, 0.4)',
      cursor: 'pointer',
      borderRadius: '10px',
    },
  }));

  

function NCard({ title, author, pnl, total, risk, price,id,current_page,price_hist,date_hist, description,status}) {
    const dateObjectHist = date_hist.map((timestamp) => new Date(timestamp * 1000));
    const navigate = useNavigate();
    const handleClick = () => {
      localStorage.setItem('port_id', id);
      localStorage.setItem('Current Page', current_page);
      navigate(`/detail`);
    }
    const [expanded, setExpanded] = useState(false);
   
  
    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    const displayStatus = current_page === 'My Ports' ? status : 'created';
    // setTotalPnl(100*((total_price/(total_price-total_pnl))**(1/2)-1));
    const totalPnl = 100*((price/(price-total))**(1/2)-1);
    return (


      <StyledCard  className={expanded ? styles.cardExpanded : styles.carde}>
        <div className={styles.card} onClick={localStorage.getItem('user_id') !== null ? handleClick : null }>
          {current_page === "My Ports" && (<div className ={styles.status}>{status.toUpperCase()}</div>)}
            <div className={styles.title}>{` ${title}`}</div>
            <div className={styles.desc}>
            {description.length > 30 ? `${description.substring(0, 30)}...` : description}
              </div>
            <div className={styles.author}>{` ${author}`}</div>
            {price_hist.length > 0 ? (<div className={styles.graph}>
                <LineGraph dataa={price_hist} labels={dateObjectHist} index={0} />
            </div>) : <div className={styles.no_graph}>PENDING</div>}
            {current_page == "My Ports" ?(<div className={styles.nested_container}>
            <div className={styles.label}>DAILY CHANGE</div>
            <div className={styles.value}>${pnl}</div>
            <div className={styles.label}>VALUE</div>
            <div className={styles.value}>${price}</div>
            <div className={styles.label_last}>RISK INDEX</div>
            <div className={styles.value_last}>{risk}/5</div>
            </div>) : (<div className={styles.nested_container_m}>
            <div className={styles.label_m}>ANNUAL RETURN</div>
            <div className={styles.value_m}>{totalPnl.toFixed(1)}%</div>
            <div className={styles.label_last_m}>RISK INDEX</div>
            <div className={styles.value_last_m}>{risk}/5</div>
            </div>)}
  

        </div>

         {/* <CardActions>
        <IconButton onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
          <ExpandMoreIcon />
        </IconButton>
      </CardActions> */}

      {/*<Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography variant="h6" sx={{ marginBottom: 2 }}>Description</Typography>
          <Typography variant="h5" sx={{ marginBottom: 2 }}>
           {description.slice(0,100)}
          </Typography>

    
          <div className={styles.label_last}>{`STATUS ${displayStatus}`}</div>
        </CardContent>


      </Collapse> */}

        </StyledCard >
    );
}


function CardDashboard() {
    return (
        <div className={styles.container}>
            <Card/>
            <Card/>
            <Card/>
            <Card/>
            <Card/>
            <Card/>
            <Card/>
            <Card/>
            <Card/>
            <Card/>
            <Card/>
            </div>
        );
}

export default NCard;