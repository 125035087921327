

// const url = 'http://localhost:443';
const url = 'https://server.invest-ports.com';
async function loginUser(username, password,recaptchaToken) {
  console.log(username)
  console.log(password)
  await fetch(`${url}/ports/user/verifyuser?recaptchaToken=${recaptchaToken}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ username, password }),
  })
  .then(response => response.json())
  .then(data => {
    localStorage.setItem('userToken', data.token);

    console.log("Token:"); // Store the token
    console.log(data.token); // Store the token
  })
  .catch(error => console.error('Error:', error));
  if (localStorage.getItem('userToken')) {
    return true;
  }
  else{
    return false;
  }
  
}

async function googleLogin(response,recaptchaToken) {

  console.log("user.js response: ",response)
  console.log("user.js recaptchaToken: ",recaptchaToken)
  await fetch(`${url}/ports/user/google-signin?request=${response}&recaptchaToken=${recaptchaToken}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    }
  })
  .then(response => response.json())
  .then(data => {
    console.log("data " ,data)
    localStorage.setItem('userToken', data.loginResponse.token);
    localStorage.setItem('username', data.username);

    console.log("Token:"); // Store the token
    console.log(data.token); // Store the token
  })
  .catch(error => console.error('Error:', error));
  if (localStorage.getItem('userToken')) {
    return true;
  }
  else{
    return false;
  }
  
}

function makeAuthenticatedRequest() {
  const token = localStorage.getItem('userToken');
  fetch('${url}/protected', {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
  .then(response => response.json())
  .catch(error => console.error('Error:', error));
}


function getBalance(id) {
  const token = getToken();
  return fetch(`${url}/ports/user/getTotalBalance?id=${id}`,{
    method: 'GET',
     
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => response.text())
    .catch(error => {
      console.error('Error fetching users:', error);
      throw error;
    });
}
function getCash(id) {
  const token = getToken();
  return fetch(`${url}/ports/user/getCash?id=${id}`,{
    method: 'GET',
     
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => response.text())
    .catch(error => {
      console.error('Error fetching users:', error);
      throw error;
    });
}
function getInvesting(id) {
  const token = getToken();
  return fetch(`${url}/ports/user/getInvesting?id=${id}`,{
    method: 'GET',
     
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => response.text())
    .catch(error => {
      console.error('Error fetching users:', error);
      throw error;
    });
}

// Helper function to get the token from local storage
function getToken() {
  return localStorage.getItem('userToken');
}

// Fetch functions with updated URLs and headers
function getUsersMain() {
  const token = getToken();
  return fetch(`${url}/ports/Portfolio/getAll`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => response.json())
    .catch(error => {
      console.error('Error fetching users:', error);
      throw error;
    });
}

function getStocks() {
  const token = getToken();
  return fetch(`${url}/ports/stocks/getStocks`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => response.json())
    .catch(error => {
      console.error('Error fetching stocks:', error);
      throw error;
    });
}
// function getID(username) {
//   const token = getToken();
//   return fetch(`${url}/ports/user/getId?Username=${username}`, {
//     headers: {
//       'Authorization': `Bearer ${token}`,
//     },
//   })
//     .then(response => response.json())
//     .catch(error => {
//       console.error('Error fetching user ID:', error);
//       alert("Incorrect Username/Password")
//       throw error;
//     });
// }

function getLoginUsers(username) {
  const token = getToken();
  return fetch(`${url}/ports/user/getOneUsers?Username=${username}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => response.json())
    .catch(error => {
      alert("Username or password Incorrect")
      console.error('Error fetching users:', error);
      throw error;
    });
}

function getIport(username) {
  const token = getToken();
  return fetch(`${url}/ports/investments/front-end?user_id=${username}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => 
      response.json()
    )
    .catch(error => {
      console.error('Error fetching Iports:', error);
      throw error;
    });
}

async function getSport(port_id, id) {
  try {
    const token = getToken();
    const response = await fetch(`${url}/ports/investments/fron?user_id=${port_id}&portfolio_id=${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching Sport:', error);
    return null; // Return null to indicate failure
  }
}


function changeVis(id) {
  const token = getToken();
  return fetch(`${url}/ports/Portfolio/Vis?port_id=${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => response.text());
}

function getGport() {
  const token = getToken();
  return fetch(`${url}/ports/Portfolio/getAll
    `, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => response.json())
    .catch(error => {
      console.error('Error fetching Gports:', error);
      throw error;
    });
}

function getPport() {
  const token = getToken();
  return fetch(`${url}/ports/Portfolio/getPendingCards`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => response.json())
    .catch(error => {
      console.error('Error fetching Gports:', error);
      throw error;
    });
}

function createUser(username, password, email) {
  const token = getToken();
  return fetch(`${url}/ports/user/saveUsers`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ username, password, email }),
  })
  .then(response => {
  
      if (response.status === 500) {  // Assuming 409 Conflict is returned for existing username
        throw new Error("Username already exists!");
      } 
    
    return response.text();
  })
  .then(data => {
    console.log("User created successfully:", data);
  })
  .catch(error => {
    alert(error.message);  // Displaying a pop-up with the error message
  });
}
async function changeUsername(userId, username) {
  const token = getToken();
  return fetch(`${url}/ports/user/changeUsername?userId=${userId}&Username=${username}`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
  .then(response => {
    if (response.status === 500) {
      throw new Error("Failed to update username!");
    }
    return response.text();
  })
  .then(data => {
    console.log("Username updated successfully:", data);
    return data;
  })
  .catch(error => {
    alert(error.message);
  });
}

async function changeEmail(userId, email) {
  const token = getToken();
  return fetch(`${url}/ports/user/changeEmail?userId=${userId}&email=${email}`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
  .then(response => {
    if (response.status === 500) {
      throw new Error("Failed to update email!");
    }
    return response.text();
  })
  .then(data => {
    console.log("Email updated successfully:", data);
  })
  .catch(error => {
    alert(error.message);
  });
}

async function changePassword(userId, newPassword, oldPassword) {
  const token = getToken();
  return fetch(`${url}/ports/user/changePassword?userId=${userId}&password=${newPassword}&oldpassword=${oldPassword}`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
  .then(response => {
    if (response.status === 401) {
      throw new Error("Invalid old password!");
    }
    if (response.status === 500) {
      throw new Error("Failed to update password!");
    }
    return response.text();
  })
  .then(data => {
    console.log("Password updated successfully:", data);
  })
  .catch(error => {
    alert(error.message);
  });
}



function createEmail(recipient, msgBody, subject, attachment) {
  const token = getToken();
  return fetch(`${url}/ports/email/send`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({ recipient, msgBody, subject,attachment }),
  })
    .then(response => response.text());
}
function getDPorts(id) {
  const token = getToken();
  return fetch(`${url}/ports/Portfolio/getId?portfolioId=${id}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => response.json())
    .catch(error => {
      console.error('Error fetching user ports:', error);
      throw error;
    });
}

function createUserPortfolios(user_id, portfolio_id, amount) {
  const token = getToken();
  return fetch('${url}/port.user_ports', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({ user_id, portfolio_id, amount }),
  })
    .then(response => response.text());
}

async function createPort(name, author, stocks, percentages, description, position_types) {
  const token = getToken();
  console.log("stocks ", stocks);
  console.log("percentages ", percentages);
  console.log("position types ", position_types);
  const timestampInMilliseconds = Date.now();
  const data = {
    title: name,
    author: author,
    stocks: stocks.split(' '),
    percentages: percentages.split(' '),
    description: description,
    positionTypes: position_types.split(' '),

  };  
  const jsonString = JSON.stringify(data);
  const encodedJsonString = encodeURIComponent(jsonString);


  console.log(jsonString);

   return await fetch(`${url}/ports/Portfolio/create`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: jsonString,
  }).then(response => response.text())
  

}




async function createTemporaryPort(name, author, stocks, percentages, description, position_types, money_input) {
  const token = getToken();
  console.log("stocks ", stocks);
  console.log("percentages ", percentages);
  console.log("position types ", position_types);
  const timestampInMilliseconds = Date.now();
  const data = {
    title: name,
    author: author,
    stocks: stocks.split(' '),
    percentages: percentages.split(' '),
    description: description,
    positionTypes: position_types.split(' '),
    moneyInput: String(money_input),

  };  
  const jsonString = JSON.stringify(data);
  const encodedJsonString = encodeURIComponent(jsonString);





   return await fetch(`${url}/ports/Portfolio/TempCreate`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: jsonString,
  }).then(response => response.text())
  

}






function deleteUser(username) {
  const token = getToken();
  return fetch(`${url}/port.users/${username}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => response.json());
}

function updateUser(username, newUsername, newPassword) {
  const token = getToken();
  return fetch(`${url}/port.users/${username}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({ username: newUsername, password: newPassword }),
  })
    .then(response => response.json());
}


function investPort(portfolio_id, user_id, amount,temp) {
  const token = getToken();
  return fetch(`${url}/ports/investments/Invest?port_id=${portfolio_id}&user_id=${user_id}&money_input=${amount}&temp=${temp}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({ portfolio_id, user_id, amount }),
  })
    .then(response => response.text());
}

function getCards(type, user_id) {
  const token = getToken();
  console.log("user_id: ", user_id); 
  console.log("type: ", type); 
  if (user_id === 0) {
    return fetch(`${url}/ports/Portfolio/getCards?type=${type}&userId=0`, {
      method: 'GET',
    })
      .then(response => response.json());
  }else{
    return fetch(`${url}/ports/Portfolio/getCards?type=${type}&userId=${user_id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then(response => response.json());
  }

  
}
function getDetail(type, port_id, userId) {
  const token = getToken();
  return fetch(`${url}/ports/Portfolio/getDetail?type=${type}&userId=${userId}&portId=${port_id}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => response.json());
}

function DeletePort(port_id) {
  const token = getToken();
  return fetch(`${url}/ports/Portfolio/deleteTemps`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => response.text());
}
function Delete(port_id) {
  const token = getToken();
  return fetch(`${url}/ports/Portfolio/delete?portId=${port_id}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => response.text());
}
// handleRequestApproval


function requestApproval(portId) {
  const token = getToken();
  return fetch(`${url}/ports/Portfolio/setToPending?portId=${portId}`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => response.text());


}

function Approved(portId) {
  const token = getToken();
  return fetch(`${url}/ports/Portfolio/setToPublic?portId=${portId}`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => response.text());


}

function Denied(portId) {
  const token = getToken();
  return fetch(`${url}/ports/Portfolio/setToDenied?portId=${portId}`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => response.text());
}

function getChartData(idType, portId ,userId, period) {
  const token = getToken();
  return fetch(`${url}/ports/Portfolio/getChartData?idType=${idType}&userId=${userId}&portId=${portId}&period=${period}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => response.json());
}

function getStocksPerc(port_id) {
  const token = getToken();
  return fetch(`${url}/ports/Portfolio/getStocksPerc?portId=${port_id}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => response.json());
}

function getMarketStatus() {
  const token = getToken();
  return fetch(`${url}/ports/stocks/getMarketStatus`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => response.json());
}

function getUserById(userId) {
  const token = getToken();
  return fetch(`${url}/ports/user/getUserById?id=${userId}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => response.json());
  }


export { getBalance ,
  getUsersMain,
  getUserById,
  createUser, 
  deleteUser,
  changeVis, 
  getDPorts, 
  createEmail, 
  updateUser,
  getIport,
  getGport,
  createPort,
  getSport,
  getPport,
  createUserPortfolios,
  getStocks,
  loginUser,
  makeAuthenticatedRequest,
  investPort,
  requestApproval,
  getCards,
  getDetail, 
  Approved,
  Denied,
  changeUsername,
  changePassword,
  changeEmail,
  getChartData,
  getStocksPerc,
  DeletePort,
  createTemporaryPort,
  getMarketStatus,
  Delete,
  getLoginUsers,
  getCash,
  getInvesting,
  googleLogin
 };