import React, { useState, useEffect } from 'react';
import s from './detail.module.css';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { formatISO } from 'date-fns';
import 'chartjs-adapter-date-fns';
import { IgrFinancialChart, IgrFinancialChartModule, IgrLineSeries, IgrCategoryXAxis, IgrNumericYAxis  } from "igniteui-react-charts";
import jsonData from './sample.json';
import { getChartData, getStocksPerc, getMarketStatus,Delete } from '../user';
import LoadModal from '../New_Home/loading_modal';
import moment from 'moment-timezone';
import SideMenu from './dashboard/components/SideMenu';
import StockDropDown from './StockDropDown';
import { TemplateMakeAPort } from '../New_Make_A_Port/page';
import { useNavigate } from 'react-router-dom';
Chart.register(...registerables);
// IgrFinancialChartModule.register();


function aggregateData(labels, data, unit, index) {
  let newIndex = index;
  const aggregatedData = {};
  let indexInterval;
  if(labels.length != 0){
  const indexDate = new Date(labels[index] * 1000);
  
  if (unit === 'day') {
    indexInterval = new Date(indexDate.getFullYear(), indexDate.getMonth(), indexDate.getDate(),16).toISOString();
  } else if (unit === 'hour') {
    indexInterval = new Date(indexDate.getFullYear(), indexDate.getMonth(), indexDate.getDate(), indexDate.getHours()).toISOString();
  } else if (unit === 'minute') {
    indexInterval = new Date(indexDate.getFullYear(), indexDate.getMonth(), indexDate.getDate(), indexDate.getHours(), indexDate.getMinutes()).toISOString();
  }
}
  
  labels.forEach((timestamp, index) => {
    const date = new Date(timestamp * 1000);
    let interval;
    if (unit === 'day') {
      interval = new Date(date.getFullYear(), date.getMonth(), date.getDate(),16).toISOString();
    } else if (unit === 'hour') {
      interval = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours()).toISOString();
    } else if (unit === 'minute') {
      interval = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()).toISOString();
    }
    
    if (!aggregatedData[interval]) {
      aggregatedData[interval] = { value: 0 };
    }
    
    aggregatedData[interval].value = data[index];
  });

  
  const aggregatedLabels = [];
  const aggregatedValues = [];
  for (const interval in aggregatedData) {
    aggregatedLabels.push(new Date(interval));
    aggregatedValues.push(aggregatedData[interval].value); // Average value for the day
  }
  if(indexInterval){
    newIndex = aggregatedLabels.map(date => date.toISOString()).indexOf(indexInterval);
  }
  
  
  return { aggregatedLabels, aggregatedValues, newIndex };
}


function LineGraph({ data, index, labels, unit }) {
  const aggregatedData = aggregateData(labels, data, unit, index);
  const dateLabels = aggregatedData.aggregatedLabels;
  const dataValues = aggregatedData.aggregatedValues;
  const newIndex = aggregatedData.newIndex;
  const datas = {
    labels: dateLabels,
    datasets: [
      {
        label: 'Back Test Data',
        data: dataValues,
        fill: false,
        // backgroundColor: '#949494',
        // borderColor: '#1B263B', // Default border color
        segment: {
          borderColor: (context) => {
            return context.p0DataIndex < newIndex ? "#949494" : '#1B263B';
          },
        },
          pointBackgroundColor: (context) => {
            return context.dataIndex === dataValues.length - 1 ? '#1B263B' : "#949494";
          },
          pointRadius: (context) => {
            return context.dataIndex === dataValues.length - 1 ? 5 : 0; // Make the last point radius bigger
          },
          pointBorderWidth: 0,
          pointBorderColor: 'transparent',
          
        cubicInterpolationMode: 'monotone',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        labels: {
          color: '#1B263B',
        },
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          title: function (context) {
            const date = new Date(context[0].parsed.x);
            if (unit === 'day') {
              return date.toLocaleDateString()
            } else{
              return date.toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true,
              });
            }
            
          },
          label: function (context) {
            const label = context.dataIndex < newIndex ? 'BackTest Data' : 'Live Data';
            const value = context.raw;
            return `${label}: ${value.toFixed(2)}`;
          },
          labelColor: function(context) {
            return {
              borderColor: context.dataIndex < newIndex ? "#949494" : '#1B263B',
              backgroundColor: context.dataIndex < newIndex ? "#949494" : '#1B263B',
            };
          }
        },
      },
    },
    // interaction: {
    //   mode: 'x',
    //   intersect: false,
    //   axis: 'xy',
    // },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: unit, // Adjust the unit as needed (e.g., 'minute', 'hour', 'day', 'month')
          tooltipFormat: 'PP', // Format for the tooltip
          displayFormats: {
            month: 'MMM yyyy',
            day: 'MMM d', // Format for the x-axis labels
            hour: 'd h a',
            minute: 'h:mm a',
            month: 'MMM yyyy',
          },
        },
        ticks: {
          callback: function(value, index, values) {
            const date = new Date(value);
            // get the day of the week
            const day = date.getDay();
            const hours = date.getHours();
            const minutes = value.getMinutes();
            // Filter out times between 4:30 PM and 9:30 AM
            if ((hours > 9 || (hours === 9 && minutes >= 30)) && (hours < 16)) {
              if (day === 0 || day === 6) {
                return null;
              } else {
                return value.toLocaleTimeString(); // Adjust this to your preferred time format
              }// Adjust this to your preferred time format
            }
            return null;
          }
        },
        title: {
          display: true,
          text: 'Date',
          color: '#1B263B',
        },
        ticks: {
          color: '#1B263B',
        },
      },
      y: {
        ticks: {
          display: true,
          text: 'Value',
          color: '#1B263B',
        },
        grid: {
          color: '#949494',
          borderColor: 'red',
        },
      },
    },
  };
  return (
    <div className={s.graphContainer}>
      <Line data={datas} options={options} className="canvas" />
    </div>
  );
};



function GrayedGraph({ data, index, labels, unit }) {
  const aggregatedData = aggregateData(labels, data, unit, index);
  const dateLabels = aggregatedData.aggregatedLabels;
  const dataValues = aggregatedData.aggregatedValues;
  const newIndex = aggregatedData.newIndex;
  const datas = {
    labels: dateLabels,
    datasets: [
      {
        label: 'Back Test Data',
        data: dataValues,
        fill: false,
        backgroundColor: '#949494',
        borderColor: '#949494',
        pointBackgroundColor: '#949494',
         // Default border color
          pointRadius: (context) => {
            return context.dataIndex === dataValues.length - 1 ? 5 : 0; // Make the last point radius bigger
          },
          pointBorderWidth: 0,
          pointBorderColor: 'transparent',
          
        cubicInterpolationMode: 'monotone',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        labels: {
          color: '#1B263B',
        },
      },
    },
    // interaction: {
    //   mode: 'x',
    //   intersect: false,
    //   axis: 'xy',
    // },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: unit, // Adjust the unit as needed (e.g., 'minute', 'hour', 'day', 'month')
          tooltipFormat: 'PP', // Format for the tooltip
          displayFormats: {
            month: 'MMM yyyy',
            day: 'MMM d', // Format for the x-axis labels
            hour: 'd h a',
            minute: 'h:mm a',
            month: 'MMM yyyy',
          },
        },
        ticks: {
          callback: function(value, index, values) {
            const date = new Date(value);
            // get the day of the week
            const day = date.getDay();
            const hours = date.getHours();
            const minutes = value.getMinutes();
            // Filter out times between 4:30 PM and 9:30 AM
            if ((hours > 9 || (hours === 9 && minutes >= 30)) && (hours < 16)) {
              if (day === 0 || day === 6) {
                return null;
              } else {
                return value.toLocaleTimeString(); // Adjust this to your preferred time format
              }// Adjust this to your preferred time format
            }
            return null;
          }
        },
        title: {
          display: true,
          text: 'Date',
          color: '#1B263B',
        },
        ticks: {
          color: '#1B263B',
        },
      },
      y: {
        ticks: {
          display: false,
          text: 'Value',
          color: '#1B263B',
        },
        grid: {
          color: '#949494',
          borderColor: 'red',
        },
      },
    },
  };
  return (
    <div className={s.graphContainer}>
      <Line data={datas} options={options} className="canvas" />
    </div>
  );
};

function Detail({ author, 
  status, 
  number_of_stocks, 
  total_pnl, today_pnl, 
  risk, max_drawdown, 
  total_price, 
  expense_ratio, 
  start_value, 
  description, 
  investing, 
  handleInvest, 
  handleDivest, 
  amount, 
  setAmount, 
  handleRequestApproval, 
  handleApproved, 
  handleDenied, 
  loading,
error,
reserve }) {
  const current_page = localStorage.getItem("Current Page");
  const [selectedTimeframe, setSelectedTimeframe] = useState('1D');
  const [priceHist, setPriceHist] = useState([]);
  const [dateObjectHist, setDateObjectHist] = useState([]);
  const timeframes = ['1D', '5D', '1M', '6M', '1Y', 'MAX'];
  const [index, setIndex] = useState(0);
  const [unit, setUnit] = useState('minute');
  const [isOwner, setIsOwner] = useState(false);
  const [showStocks, setShowStocks] = useState(false);
  const [stockItems, setStockItems] = useState([]);
  const [editing , setEditing] = useState(false);
  const [isMarketOpen, setIsMarketOpen] = useState(false);
  const [totalPnl, setTotalPnl] = useState(0);
  const navigate = useNavigate();

  class StockItem {
    constructor(date, open) {
      this.date = date;
      this.open = open;
    }
  }

  
  const Felete = async () => {
    await Delete(localStorage.getItem('port_id'));
    navigate('/home')
  }
  useEffect( () => {



    setTotalPnl(100*((total_price/(total_price-total_pnl))**(1/2)-1));
    const checkOwner = async() => {
      if(localStorage.getItem('username') == author){
        setStockItems(await getStocksPerc(localStorage.getItem('port_id')));
        setIsOwner(true);
      }
    }
    const fetchIsMarketOpen = async () => {
      const marketStatus = await getMarketStatus();
      setIsMarketOpen(marketStatus);
    }

    const fetchChartData = async () => {
      let chart_data;
    if(current_page === 'My Ports'){
      chart_data = await getChartData('invest',localStorage.getItem('port_id'),localStorage.getItem('user_id'), selectedTimeframe);
    }else{
      chart_data = await getChartData('port',localStorage.getItem('port_id'),localStorage.getItem('user_id'), selectedTimeframe);
    }
    console.log("chart_data: ",chart_data);
    const dateObjectHist = [];
    const priceHist = [];
    if(selectedTimeframe === '1D'){
      setUnit('minute');
    }else if(selectedTimeframe === '5D'){
      setUnit('hour');
    }else if(selectedTimeframe === '1M'){
      setUnit('day');
    }else if(selectedTimeframe === '6M'){
      setUnit('day');
    }else if(selectedTimeframe === '1Y'){
      setUnit('day');
    }else if(selectedTimeframe === 'MAX'){
      setUnit('day');
    }
    // console.log("length: ",chart_data.date_hist.length);
    for (let i = 0; i < chart_data.date_hist.length; i++) {
      const dateStr = chart_data.date_hist[i];
      const valueStr = chart_data.value_hist[i];
      let dateObj = new Date(dateStr * 1000);
      
      dateObjectHist.push(dateObj);
      priceHist.push(parseFloat(valueStr));
    }
    setDateObjectHist(chart_data.date_hist);
    console.log("start_value: ",start_value);
    console.log("date_hist: ",chart_data.date_hist[0]);
    const index = chart_data.date_hist.indexOf(start_value);
    setPriceHist(priceHist);
    if(index == -1){
      if(start_value == 0){
        setIndex(0);
      }
      setIndex(0);
    } else{
      setIndex(index);
    }
  };
  fetchChartData();
  checkOwner();
  fetchIsMarketOpen();
    


  }, [total_price,selectedTimeframe]);
  
  const handleshowStocks = () => {
    setShowStocks(!showStocks);
  }
  const handleEdit = () => {
    setEditing(!editing);
  }

    
  
  
  // let copyStockItems = [...stockItems];
  // let data1 = copyStockItems.splice(0, index-1);
  // let data2 = copyStockItems.splice(index-1, stockItems.length);
  // console.log("data1: ",data1);
  // console.log("data2: ",data2);
  return (
    <div className={s.background}>

         

           {/* <SideMenu/> */}

      {loading && <LoadModal />}
      <div className={s.grid_container}>
        <div>
        {priceHist.length > 0 && (<div className={s.timeframeContainer}>
      {timeframes.map((timeframe) => (
        <div
          key={timeframe}
          className={`${s.timeframeItem} ${selectedTimeframe === timeframe ? s.selected : ''}`}
          onClick={() => setSelectedTimeframe(timeframe)}
        >
          {timeframe}
        </div>
      ))}
    </div>)}
    {priceHist.length > 0 ? (<div className={s.graph}>
      {reserve != 0 && total_price == 0 ? (<GrayedGraph data={priceHist} labels={dateObjectHist} index={index} unit={unit} />) : (<LineGraph data={priceHist} labels={dateObjectHist} index={index} unit={unit} />)}
      {/* <LineGraph data={priceHist} labels={dateObjectHist} index={index} unit={unit} /> */}
            </div>) : <div className={s.no_graph}>PENDING</div>}
        </div>
        {/* <div className={s.graph}> */}
          {/* Financial Chart using Infragistics */}
          {/* <IgrFinancialChart
          
            width="100%"
            height="400px"
            // chartType="Spline"
            thickness={2}
            chartTitle="Port PNL"
            yAxisMode="Numeric"
            yAxisTitle="Price"
            zoomSliderType="None"
            // smoothingMode="CubicBezier"
             brushes="#1B263B"
            dataSource={stockItems}
          >
    </IgrFinancialChart> */}
        {/* </div> */}
        <div className={s.content_container}>
          <div className={s.quick_info_grid}>
            <div className={s.quick_info_label}>AUTHOR</div>
            <div className={s.quick_info_value}>{author}</div>
            {current_page === 'My Ports' &&
              <div className={s.quick_info_label}>STATUS</div>
              
            }
            {current_page === 'My Ports' &&
              <div className={s.quick_info_value}>{status}</div>
            }
            <>{isOwner && (current_page != "My Ports" ? (<div className={s.stock_button_m} onClick={handleshowStocks}>{showStocks ? '▼' : '►'}</div>) :
             (<div className={s.stock_button} onClick={handleshowStocks}>{showStocks ? '▼' : '►'}</div>))}
             <div className={s.quick_info_label}>STOCKS</div></>
            <div className={s.quick_info_value}>{number_of_stocks}</div>



            {showStocks&&(<StockDropDown data ={stockItems}/>)}
            <div className={s.quick_info_label}>VALUE</div>
            <div className={s.quick_info_value}>${total_price}</div>
            <div className={s.quick_info_label}>ANNUAL RETURN</div>
            <div className={s.quick_info_value}>{totalPnl.toFixed(1)}%</div>
            <div className={s.quick_info_label}>DAILY CHANGE</div>
            <div className={s.quick_info_value}>${today_pnl}</div>
            <div className={s.quick_info_label}>RISK INDEX</div>
            <div className={s.quick_info_value}>{risk}/5</div>
            {/* <div className={s.quick_info_label}>MAX DRAWDOWN</div>
            <div className={s.quick_info_value}>${max_drawdown}</div> */}
            <div className={s.quick_info_label_last}>EXPENSE RATIO</div>
            <div className={s.quick_info_value_last}>{expense_ratio}%</div>
          </div>
          <div className={s.action_container}>
          {/* <div>Invest Amount</div> */}
           <div className={s.input_container}>{/*$<input className={s.input} value={amount} onChange={(e) => setAmount(e.target.value)}></input>  */}
          {reserve != 0 && (
              <div className={s.reserve}>
                {reserve > 0 ? `BUYING ${reserve.toFixed(2)} @ OPEN (9:30 EST)` : `SELLING ${(-reserve).toFixed(2)} @ OPEN (9:30 EST)`}
                
                </div>
            )}
            </div>
          <div className={s.button_container}>
            {current_page === 'Secret' ? <button className={s.button} onClick={handleApproved}>APPROVE</button> : 
            <button className={s.button} onClick={handleInvest}>BUY</button>}
            {current_page === "My Ports" && (
              <button className={s.button} onClick={handleDivest}>SELL</button>
            )}
            {current_page === "Secret" && (
              <button className={s.button} onClick={handleDenied}>DENY</button>
            )}
          </div>
          
          {/* <div>{error}</div> */}
          <textarea className={s.desc} value={description} readOnly></textarea>
          {status == "PRIVATE" && <button className={s.button} onClick={handleRequestApproval}>REQUEST TO GO PUBLIC</button>}
          {/* { isMarketOpen && isOwner && <button className={s.button} onClick={handleEdit}>{!editing ? "ENTER " : "EXIT "}EDIT MODE</button>} */}
          {isOwner && <button className={s.button} onClick={Felete}>Delete Port</button>}
        </div>
        </div>
      </div>
      {editing && (<TemplateMakeAPort isEditing={true}/>)}
    </div>
  );
}

export default Detail;
