import React, { useContext, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import Login from './login_form/new_login';
import Register from './login_form/new_register';
import { AuthProvider, AuthContext } from './Authentication';
import DetailPage from './New_Detail/detail_page';
import Landing from './New_Landing_Page/landing';
import MakeAPort from './New_Make_A_Port/page';
import Home from './New_Home/home';
import GDPRComplianceNotice from './privacy/PrivacyPolicy';
import Market from './New_Port_Market/market';
// TODO: i moved market and secret into home bc theyre all essentially the same code so just dont edit line 13
import Secret from './New_Home/home';
import NewDetailPage from './secret_home/detail_page';
import { getUsers, loginUser, makeAuthenticatedRequest } from './user';
import Settings from './settings/settings'
import { withOneTabEnforcer } from "react-one-tab-enforcer"
import PortfolioManager from './Edit/Edit';

// import './App.css';

// Timer logic
const useSessionTimeout = (timeoutDuration) => {
  const navigate = useNavigate();

  useEffect(() => {
    
    const timer = setTimeout(() => {
      // Redirect to login page with an error message
      navigate('/login', { state: { error: 'Session expired. Please log in again.' } });
      localStorage.clear(); 
      
    }, timeoutDuration);

    return () => clearTimeout(timer); // Clear timer on component unmount
  }, [navigate, timeoutDuration]);

};




const PrivateRoute = ({ element: Component, ...rest }) => {
  const token = localStorage.getItem('userToken');
  const { logout } = useContext(AuthContext);

  useSessionTimeout(900000, logout); // Pass the logout function to the hook

  return token ? <Component {...rest} /> : <Navigate to="/login" />;
};

function App() {




  return (
    <div className="App" id="root">
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/market" element={<Home current_page='Port Market' />} />
            <Route path="/home" element={<PrivateRoute element={Home} current_page="My Ports" />} />
            <Route path="/addport" element={<PrivateRoute element={MakeAPort} />} />
            <Route path="/privacy" element={<GDPRComplianceNotice/>} />
            <Route path="/detail" element={<PrivateRoute element={DetailPage}  />} />
            {/* TODO: i moved market and secret into home bc theyre all essentially the same code so just dont edit line 56 */}
            <Route path="/secret_home" element={<PrivateRoute element={Secret} current_page="Secret" />} />
            {/* <Route path="/newDetail" element={<PrivateRoute element={NewDetailPage} />} /> */}
            <Route path="/settings" element={<PrivateRoute element={Settings}/>} />
            <Route path="/portfolioManager" element={<PrivateRoute element={PortfolioManager}/>} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}


const DifferentWarningComponent = () => <div>Sorry! You can only have this application opened in one tab.</div>
export default withOneTabEnforcer({appName: "ports", OnlyOneTabComponent: DifferentWarningComponent})(App)






