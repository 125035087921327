import React, { useState, useEffect } from 'react';
import './FinTechLandingPage.css'; // Custom CSS file
import { ArrowRight, ChevronDown, DollarSign, PieChart, Shield, Smartphone, Users, Zap } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="feature-card">
    <Icon className="feature-icon" />
    <h3 className="feature-title">{title}</h3>
    <p className="feature-description">{description}</p>
  </div>
);

const FinTechLandingPage = ({aboutPressed, setAboutPressed, rerender} ) => {
  const navigate = useNavigate();
  useEffect(() => {
    console.log('rerender:', rerender);
    console.log('aboutPressed:', aboutPressed);
    if (aboutPressed){
    const element = document.getElementById('about');
    if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    setAboutPressed(false);
  }

  }, [rerender]);
    const handleButtonClick = () => {
      localStorage.setItem('user_id', 0);
    navigate('/market')

  };  
  return (
    <div className="fintech-landing-page">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1 className="hero-title">A Simpler and Cheaper Way to Diversify
          </h1>
          <p className="hero-subtitle">
          Search for user-curated portfolios, custom built with safety and security in mind. Start investing with as little as $5.
          </p>
          <button className="cta-button"       onClick={handleButtonClick}>
           See The Ports! <ArrowRight className="cta-icon" />
          </button>
        </div>
        {/* <div className="hero-image">
          <img src="" alt="Financial dashboard" className="hero-img" />
        </div> */}
        {/* <div className="hero-shape">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
          </svg>
        </div>  */}
      </section>

      {/* Features Section */}
      <section id='about' className="features-section">
        <div className="container">
          <h2 className="section-title">Our Features</h2>
          <div className="features-grid">
            <FeatureCard icon={PieChart} title="Partial Purchasing" description="By investing in fractional shares, we can diversify your investments starting at just $5." />
            <FeatureCard icon={ DollarSign} title="Creator Rewards" description="As a creator, you earn a percentage of the investments in your port, encouraging you to perform consistently." />
            <FeatureCard icon={Smartphone} title="Beginner Friendly" description="Whether you’re a creator or an investor (or both!), we make it easy to sign up and get going." />
            <FeatureCard icon={ Shield} title="Secure Transactions" description="By entering into positions through a centralized brokerage, we can ensure all positions are correctly allocated." />
            <FeatureCard icon={Zap} title="Transparency" description="Even though exact positions aren’t disclosed, by making ports public you can view and compare their information." />
            <FeatureCard icon={Users} title="Simplicity" description="While we provide a wide variety of metrics to make a decision, we highlight the most important ones." />
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="how-it-works-section" style={{ backgroundImage: "url('/api/placeholder/1920/1080')" }}>
        <div className="how-it-works-container">
          <h2 className="section-title">How It Works</h2>
          <div className="steps">
            <div className="step">
              <div className="step-number">1</div>
              <h3 className="step-title">Sign Up</h3>
              <p className="step-description">Create your account in minutes</p>
            </div>
            <div className="step">
              <div className="step-number">2</div>
              <h3 className="step-title">Browse the Ports</h3>
              <p className="step-description">Check out what our users have created</p>
            </div>
            <div className="step">
              <div className="step-number">3</div>
              <h3 className="step-title">Create or Invest</h3>
              <p className="step-description">Begin sharing or investing in diversified ports</p>
            </div>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section className="pricing-section">
        <div className="container">
          <h2 className="section-title">Simple, Transparent Pricing</h2>
          <div className="pricing-grid">
            <div className="pricing-card">
              <h3 className="pricing-title">By using a proprietary algorithm to calculate an expense ratio for every port, we ensure that you get the best deal based on risk, volatility, performance, and many other metrics.
              </h3>
              {/* <h3> By having a base 1% management fee on every port, our investors and creators can worry less about how the ports operate</h3> */}
        
          
            
            </div>
         
          </div>
        </div>
      </section>
    </div>
  );
};

export default FinTechLandingPage;
