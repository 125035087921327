import React, { useState, useEffect } from 'react';
import styles from './modal.module.css';
import { Denied, getLoginUsers, createEmail, investPort, getDPorts} from '../user';
import { useNavigate } from 'react-router-dom';



function DenyModal(data) {  
    const [reason, setReason] = useState('');
    const navigate = useNavigate();

    const handleDenied = async () => {
      getLoginUsers(data.author)
      .then(f => {
        createEmail(f['email'],`Thank you for submitting your port “${data.name}” for review! Your port has been approved and is now public on the website.`,`${f["username"]} Port Rejection - ${data.name}`)
        console.log(f['email']); // This will log the resolved value from the fetch call
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
        await Denied(localStorage.getItem('port_id'));
        navigate("/secret_home")
      }
    
    return (
        <div className={styles.modal_overlay}>
            <div className={styles.modal}>
                <div>
                    <div>Reason</div>
                    <textarea className={styles.description_input} value={reason} onChange={(e) => setReason(e.target.value)}></textarea>
                        <div className={styles.build_button} onClick={handleDenied}>Submit</div>
                </div>

           
            </div>
        </div>
    );

}



function AmountModal({setLoading, invest,setError,handleClose,setReRender, reRender}){

  const [amount, setAmount] = useState('');
  const navigate = useNavigate();
  const handleAmountChange = (e) => {
    const value = e.target.value;
    // Allow only numbers up to one decimal place
    console.log(value);
    console.log(!value.includes('-'));
    if (/^\d*\.?\d{0,1}$/.test(value)) {
      setAmount(value);
    }
  };


  const handleInvest = async () => {
    console.log(localStorage.getItem('port_id'));
      console.log(localStorage.getItem('username'));
      console.log(amount);
      // createEmail()

      


      if (amount === undefined || amount === '') {
        setError("Please enter an amount");
        return;
      }
      if ((amount.match(/\./g) || []).length > 1) {
        setError("Invalid Input Amount: Too many decimal points");
        return;
      }
      try{
        const amountDouble = parseFloat(amount);
        if (amountDouble < 0){
          setError("Please enter a positive amount");
          return;
        }
      } catch (error){
        setError("Invalid Input Amount");
        return;
      }
      let amountDouble = parseFloat(amount);
      if (!invest){
        amountDouble = -amountDouble;
      }
      setLoading(true);
      const response = await investPort(localStorage.getItem('port_id'), localStorage.getItem('user_id'), amountDouble);
      setAmount('');
      if (response == "Reserve Divestment successful and investment deleted"){
        navigate("/home");
      }
      setError(response);
      setLoading(false);
      if (localStorage.getItem('Current Page') === 'Port Market'){
        localStorage.setItem('Current Page','My Ports');
      }
      setReRender(reRender + 1);
      handleClose();
  }



  return (
    <div className={styles.modal_overlay}>
        <div className={styles.modal}>
            <div>
                <div className={styles.modal_close_button} onClick={handleClose}>X</div>
                <div className={styles.amount_label}>Enter Amount</div>
                <div className={styles.input_container}>$<input type = "text" className={styles.input} value={amount} onChange={handleAmountChange} placeholder='$5 minimum'></input></div>
                    <div className={styles.build_button} onClick={handleInvest}>{invest ? "Confirm Buy" : "Confirm Sell"}</div>
            </div>

       
        </div>
    </div>
);
}

export{AmountModal, DenyModal};